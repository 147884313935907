:root {

    // --green-1: #8CBFA6;
    // --green-2: #5FAC93;
    // --green-3: #4d856d;

	--green-1: #A1BCB1;
    --green-2: #7EA494;
    --green-3: #5F8575;


	--grape-1:#F76D87;
	--grape-2:#EC5564;
	--grape-3:#D94452;
	--bitter-1:#FC8370;
	--bitter-2:#FB6D51;
	--bitter-3:#EB563F;
	--sunflo-1:#FCD277;
	--sunflo-2:#FECD57;
	--sunflo-3:#F5BA45;
	--grass-1:#B4E080;
	--grass-2:#9ED36A;
	--grass-3:#8AC054;
	--mint-1:#6ADDBD;
	--mint-2:#46CEAD;
	--mint-3:#35BB9B;
	--aqua-1:#82D2ED;
	--aqua-2:#6FBCE4;
	--aqua-3:#5DAAD5;
	--blue-1:#73B1F4;
	--blue-2:#5E9CEA;
	--blue-3:#4B89DA;
	--lav-1:#B3A5EF;
	--lav-2:#AC96EA;
	--lav-3:#967ADA;
	--pink-1:#F299CE;
	--pink-2:#EB87BF;
	--pink-3:#D670AC;
	--skin-1:#F4D0B5;
	--skin-2:#F0C8A5;
	--skin-3:#E4B693;
	--lg-1: #F4F6F9;
    --lg-2: #E5E8E7;
    --lg-3: #CBD0D8;
    --dg-1: #A9B1BC;
    --dg-2: #646c77;
    --dg-3: #424953;

    /*background-color: #58734e;*/
}


.fs-9 {
	font-size: .9em;
}
.fs-09 {
	font-size: .9em;
}
.fs-08 {
	font-size: .8em;
}
.fs-07 {
	font-size: .7em;
}
.fs-06 {
	font-size: .6em;
}

.w-shadow {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	/* box-shadow: 
	85px 85px 58px rgb(0 0 0 / 3%), 
	35.51px 35.51px 24.23px rgb(0 0 0 / 2%), 
	18.99px 18.99px 12.96px rgb(0 0 0 / 2%), 
	10.64px 10.64px 7.26px rgb(0 0 0 / 2%), 
	5.65px 5.65px 3.86px rgb(0 0 0 / 1%), 
	2.35px 2.35px 1.61px rgb(0 0 0 / 1%); */
}

.text-blur {
	color: transparent;
	text-shadow: 0 0 6px #424953;
}

.home-logo {
	width: 60%;
}
@media screen and (min-width: 48em) {
	.home-logo {
		width: 50%;
	}
}
@media screen and (min-width: 72em) {
	.home-logo {
		width: 40%;
		max-width: 40rem;
	}
}

.img-cover {
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-height: 20rem;
}

@media screen and (min-width: 48em) {
	.img-cover {
		max-height: 28rem;
	}
}

.img-full-width {
	object-fit: cover;
	width: 100%;
	max-height: 35rem;
}

@media screen and (min-width: 75em) {
	.img-full-width {
		max-height: 45rem;
	}
}


/*******************************
    BOOTSTRAP COLORS
********************************/
.bg-primary {
	color: var(--blue-3) !important;
	background-color: var(--blue-1) !important;
}
.bg-secondary {
	color: var(--dg-3) !important;
	background-color: var(--lg-1) !important;
}
.bg-success {
	color: var(--grass-3) !important;
	background-color: var(--grass-1) !important;
}
.bg-danger {
    color: var(--lg-1) !important;
	background-color: var(--grape-1) !important;
}
.bg-warning {
	color: var(--dg-3) !important;
	background-color: var(--sunflo-1) !important;
}
.bg-info {
	color: var(--dg-3) !important;
	background-color: var(--aqua-1) !important;
}
.bg-light {
	color: var(--dg-3) !important;
	background-color: var(--lg-1) !important;
}
.bg-dark {
	background-color: var(--dg-1) !important;
}

.btn-primary,
.badge-primary {
	color: white !important;
    background-color: var(--blue-2) !important;

	&:hover,
	&:focus {
    	background-color: var(--blue-3) !important;
	}
}
.btn-outline-primary {
	color: var(--blue-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--blue-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--blue-3) !important;
		border: 2px solid var(--blue-3) !important;
	}
}
.btn-link-primary {
	color: var(--blue-1) !important;
	transition: .4s;
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--blue-2) !important;
		transform: scale(1.10);
		cursor: pointer;
	}
}
.btn-link-primary-cheked {
	color: var(--blue-3) !important;
	transition: .4s;
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--blue-2) !important;
		transform: scale(1.10);
		cursor: pointer;
	}
}

.btn-secondary,
.badge-secondary {
	color: var(--dg-3) !important;
    background-color: var(--lg-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--lg-3) !important;
	}
}
.btn-outline-secondary {
	color: var(--lg-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--lg-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--lg-3) !important;
		border: 2px solid var(--lg-3) !important;
	}
}
.btn-link-secondary {
	color: var(--lg-2) !important;
	transition: .4s;
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--lg-3) !important;
		transform: scale(1.10);
		cursor: pointer;
	}
}


.btn-success,
.badge-success {
	color: white !important;
    background-color: var(--grass-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--grass-3) !important;
	}
}
.btn-outline-success {
	color: var(--grass-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--grass-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--grass-3) !important;
		border: 2px solid var(--grass-3) !important;
	}
}


.btn-danger,
.badge-danger {
	color: white !important;
    background-color: var(--grape-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--grape-3) !important;
	}
}
.btn-outline-danger {
	color: var(--grape-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--grape-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--grape-3) !important;
		border: 2px solid var(--grape-3) !important;
	}
}
.btn-link-danger {
	color: var(--grape-2) !important;
	transition: .4s;
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--grape-3) !important;
		transform: scale(1.10);
		cursor: pointer;
	}
}




.btn-warning,
.badge-warning {
	color: var(--dg-3) !important;
    background-color: var(--sunflo-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--sunflo-3) !important;
	}
}
.btn-outline-warning {
	color: var(--sunflo-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--sunflo-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--sunflo-3) !important;
		border: 2px solid var(--sunflo-3) !important;
	}
}

.btn-info,
.badge-info {
	color: white !important;
    background-color: var(--aqua-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--aqua-3) !important;
	}
}
.btn-outline-info {
	color: var(--aqua-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--aqua-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--aqua-3) !important;
		border: 2px solid var(--aqua-3) !important;
	}
}

.btn-light,
.badge-light {
	color: var(--dg-3) !important;
    background-color: var(--lg-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--lg-3) !important;
	}
}
.btn-outline-light {
	color: var(--lg-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--lg-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--lg-3) !important;
		border: 2px solid var(--lg-3) !important;
	}
}

.btn-dark,
.badge-dark {
	color: white !important;
    background-color: var(--dg-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--dg-3) !important;
	}
}
.btn-outline-dark {
	color: var(--dg-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--dg-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--dg-3) !important;
		border: 2px solid var(--dg-3) !important;
	}
}

.btn-link {
	color: var(--blue-1) !important;
	text-decoration: none;
	
	&:hover,
	&:focus {
		color: var(--blue-3) !important;
	}
}

.btn-white,
.badge-white {
	color: var(--dg-3) !important;
    background-color: white !important;
	
	&:hover,
	&:focus {
		background-color: var(--dg-3) !important;
		color: white !important;
	}
}
.btn-outline-white {
	color: white !important;
    background-color: transparent !important;
	border: 2px solid white !important;

	&:hover,
	&:focus {
		color: var(--dg-2) !important;
    	background-color: var(--lg-1) !important;
	}
}

.btn-green,
.badge-green {
	color: white !important;
    background-color: var(--green-2) !important;
	border: 2px solid var(--green-2) !important;

	&:hover,
	&:focus {
    	background-color: var(--green-3) !important;
		border: 2px solid var(--green-3) !important;
	}
}
.btn-outline-green {
	color: var(--green-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--green-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--green-3) !important;
		border: 2px solid var(--green-3) !important;
	}
}
.btn-link-green {
	color: var(--green-2) !important;
	transition: .4s;
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--green-3) !important;
		transform: scale(1.05);
		cursor: pointer;
	}
}


.btn-admin {
	color: white !important;
    background-color: var(--pink-2) !important;
	
	&:hover,
	&:focus {
		background-color: var(--pink-3) !important;
	}
}
.btn-outline-admin {
	color: var(--pink-2) !important;
    background-color: transparent !important;
	border: 2px solid var(--pink-2) !important;

	&:hover,
	&:focus {
		color: white !important;
    	background-color: var(--pink-3) !important;
		border: 2px solid var(--pink-3) !important;
	}
}
.btn-link-admin {
	color: var(--pink-1) !important;
	transition: .3s;
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--pink-2) !important;
		cursor: pointer;
	}
}



.alert-success {
	background-color: white;
	color: var(--grass-3);
}
.alert-danger {
	background-color: white;
	color: var(--grape-3);
}
.alert-warning {
	background-color: white;
	color: var(--sunflo-3);
}
.alert-info {
	background-color: white;
	color: var(--aqua-3);
}
.alert-light {
	background-color: white;
	color: var(--lg-3);
}
.alert-dark {
	background-color: white;
	color: var(--dg-3);
}
.alert-secondary {
	background-color: white;
	color: var(--blue-3);
}

/*****
BTN REACTION 
****/
.btn-reaction {
	transition: .3s;
	text-decoration: none !important;
	cursor: pointer;

	display: flex;
	align-items: center;

	&-grape {
		@extend .btn-reaction;
		color: var(--grape-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-grape;
		}
	}

	&-sunflo {
		@extend .btn-reaction;
		color: var(--sunflo-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-sunflo;
		}
	}

	&-blue {
		@extend .btn-reaction;
		color: var(--blue-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-blue;
		}
	}

	&-bitter {
		@extend .btn-reaction;
		color: var(--bitter-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-bitter;
		}
	}

	&-aqua {
		@extend .btn-reaction;
		color: var(--aqua-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-aqua;
		}
	}

	&-grass {
		@extend .btn-reaction;
		color: var(--grass-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-grass;
		}
	}

	&-pink {
		@extend .btn-reaction;
		color: var(--pink-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-pink;
		}
	}
	
	&-skin {
		@extend .btn-reaction;
		color: var(--skin-2);

		&:hover,
		&:focus {
			@extend .btn-reaction-skin;
		}
	}

	&-grey {
		@extend .btn-reaction;
		color: var(--lg-3);

		&:hover,
		&:focus {
			@extend .btn-reaction-grey;
		}
	}

	&:hover,
	&:focus {
		transform: scale(1.05)  translateY(-.2em);
	}
	
}



/*******************************
    Toutes mes couleurs
********************************/

.bg-white {
	background-color:white !important;
}


/* Grapefruit */
.grape-1 {
	color: var(--grape-1) !important;
}
.grape-2 {
	color: var(--grape-2) !important;
}
.grape-3 {
	color: var(--grape-3) !important;
}

.bg-grape {
	color: white !important;
	&-1 {
		background-color: var(--grape-1) !important;
	}
	&-2 {
		background-color: var(--grape-2) !important;
	}
	&-3 {
		background-color: var(--grape-3) !important;
	}
}

.border-grape-1 {
	border:2px solid var(--grape-1) !important;
}
.border-grape-2 {
	border:2px solid var(--grape-2) !important;
}
.border-grape-3 {
	border:2px solid var(--grape-3) !important;
}


/* Bittersweet */
.bitter-1 {
	color: var(--bitter-1) !important;
}
.bitter-2 {
	color: var(--bitter-2) !important;
}
.bitter-3 {
	color: var(--bitter-3) !important;
}
.bg-bitter {
	color: white !important;
	&-1 {
		background-color: var(--bitter-1) !important;
	}
	&-2 {
		background-color: var(--bitter-2) !important;
	}
	&-3 {
		background-color: var(--bitter-3) !important;
	}
}
.border-bitter-1 {
	border:2px solid var(--bitter-1) !important;
}
.border-bitter-2 {
	border:2px solid var(--bitter-2) !important;
}
.border-bitter-3 {
	border:2px solid var(--bitter-3) !important;
}


/* Sunflower */
.sunflo-1 {
	color: var(--sunflo-1) !important;
}
.sunflo-2 {
	color: var(--sunflo-2) !important;
}
.sunflo-3 {
	color: var(--sunflo-3) !important;
}
.bg-sunflo {
	color: white;

	&-1 {
		background-color: var(--sunflo-1) !important;
	}
	&-2 {
		background-color: var(--sunflo-2) !important;
	}
	&-3 {
		background-color: var(--sunflo-3) !important;
	}
}
.border-sunflo-1 {
	border:2px solid  var(--sunflo-1) !important;
}
.border-sunflo-2 {
	border:2px solid  var(--sunflo-2) !important;
}
.border-sunflo-3 {
	border:2px solid  var(--sunflo-3) !important;
}


/* Grass */
.grass-1 {
	color: var(--grass-1) !important;
}
.grass-2 {
	color: var(--grass-2) !important;
}
.grass-3 {
	color: var(--grass-3) !important;
}
.bg-grass {
	color: white;
	
	&-1 {
		@extend .bg-grass;
		background-color: var(--grass-1) !important;
	}
	&-2 {
		@extend .bg-grass;
		background-color: var(--grass-2) !important;
	}
	&-3 {
		@extend .bg-grass;
		background-color: var(--grass-3) !important;
	}
}
.border-grass-1 {
	border:2px solid var(--grass-1) !important;
}
.border-grass-2 {
	border:2px solid var(--grass-2) !important;
}
.border-grass-3 {
	border:2px solid var(--grass-3) !important;
}


/* Mint */
.mint-1 {
	color: var(--mint-1) !important;
}
.mint-2 {
	color: var(--mint-2) !important;
}
.mint-3 {
	color: var(--mint-3) !important;
}
.bg-mint {
	color: white;
	
	&-1 {
		@extend .bg-mint;
		background-color: var(--mint-1) !important;
	}
	&-2 {
		@extend .bg-mint;
		background-color: var(--mint-2) !important;
	}
	&-3 {
		@extend .bg-mint;
		background-color: var(--mint-3) !important;
	}
}
.border-mint-1 {
	border:2px solid var(--mint-1) !important;
}
.border-mint-2 {
	border:2px solid var(--mint-2) !important;
}
.border-mint-3 {
	border:2px solid var(--mint-3) !important;
}


/* Aqua */
.aqua-1 {
	color: var(--aqua-1) !important;
}
.aqua-2 {
	color: var(--aqua-2) !important;
}
.aqua-3 {
	color: var(--aqua-3) !important;
}
.bg-aqua {
	color: white !important;
	
	&-1 {
		@extend .bg-aqua;
		background-color: var(--aqua-1) !important;
	}
	&-2 {
		@extend .bg-aqua;
		background-color: var(--aqua-2) !important;
	}
	&-3 {
		@extend .bg-aqua;
		background-color: var(--aqua-3) !important;
	}
}
.border-aqua-1 {
	border:2px solid var(--aqua-1) !important;
}
.border-aqua-2 {
	border:2px solid var(--aqua-2) !important;
}
.border-aqua-3 {
	border:2px solid var(--aqua-3) !important;
}


/* Blue Jeans */
.blue-1 {
	color: var(--blue-1) !important;
}
.blue-2 {
	color: var(--blue-2) !important;
}
.blue-3 {
	color: var(--blue-3) !important;
}
.bg-blue {
	color: white !important;
	
	&-1 {
		@extend .bg-blue;
		background-color: var(--blue-1) !important;
	}
	&-2 {
		@extend .bg-blue;
		background-color: var(--blue-2) !important;
	}
	&-3 {
		@extend .bg-blue;
		background-color: var(--blue-3) !important;
	}
}
.border-blue-1 {
	border:2px solid var(--blue-1) !important;
}
.border-blue-2 {
	border:2px solid var(--blue-2) !important;
}
.border-blue-3 {
	border:2px solid var(--blue-3) !important;
}



/* Lavender (violet) */
.lav-1 {
	color: var(--lav-1) !important;
}
.lav-2 {
	color: var(--lav-2) !important;
}
.lav-3 {
	color: var(--lav-3) !important;
}
.bg-lav {
	color: white !important;
	
	&-1 {
		@extend .bg-lav;
		background-color: var(--lav-1) !important;
	}
	&-2 {
		@extend .bg-lav;
		background-color: var(--lav-2) !important;
	}
	&-3 {
		@extend .bg-lav;
		background-color: var(--lav-3) !important;
	}
}
.border-lav-1 {
	border:2px solid var(--lav-1) !important;
}
.border-lav-2 {
	border:2px solid var(--lav-2) !important;
}
.border-lav-3 {
	border:2px solid var(--lav-3) !important;
}


/* Pink */
.pink-1 {
	color: var(--pink-1) !important;
}
.pink-2 {
	color: var(--pink-2) !important;
}
.pink-3 {
	color: var(--pink-3) !important;
}
.bg-pink {
	color: white !important;
	
	&-1 {
		@extend .bg-pink;
		background-color: var(--pink-1) !important;
	}
	&-2 {
		@extend .bg-pink;
		background-color: var(--pink-2) !important;
	}
	&-3 {
		@extend .bg-pink;
		background-color: var(--pink-3) !important;
	}
}
.border-pink-1 {
	border:2px solid var(--pink-1) !important;
}
.border-pink-2 {
	border:2px solid var(--pink-2) !important;
}
.border-pink-3 {
	border:2px solid var(--pink-3) !important;
}


/* Skin Tone */
.skin {
	&-1 {
		color: var(--skin-1) !important;
	}
	&-2 {
		color: var(--skin-2) !important;
	}
	&-3 {
		color: var(--skin-3) !important;
	}
}
.bg-skin {
	color: white !important;
	
	&-1 {
		@extend .bg-skin;
		background-color: var(--skin-1) !important;
	}
	&-2 {
		@extend .bg-skin;
		background-color: var(--skin-2) !important;
	}
	&-3 {
		@extend .bg-skin;
		background-color: var(--skin-3) !important;
	}
}
.border-skin {
	&-1 {
		border:2px solid var(--skin-1) !important;
	}
	&-2 {
		border:2px solid var(--skin-2) !important;
	}
	&-3 {
		border:2px solid var(--skin-3) !important;
	}
}


/* Light Gray */
.lg {
	&-1 {
		color: var(--lg-1) !important;
	}
	&-2 {
		color: var(--lg-2) !important;
	}
	&-3 {
		color: var(--lg-3) !important;
	}
}
.bg-lg {
	color: var(--dg-3) !important;
	&-1 {
		@extend .bg-lg;
		background-color: var(--lg-1) !important;
	}
	&-2 {
		@extend .bg-lg;
		background-color: var(--lg-2) !important;
	}
	&-3 {
		@extend .bg-lg;
		background-color: var(--lg-3) !important;
	}
}
.border-lg-1 {
	border:2px solid var(--lg-1) !important;
}
.border-lg-2 {
	border:2px solid var(--lg-2) !important;
}
.border-lg-3 {
	border:2px solid var(--lg-3) !important;
}


/* Dark Gray */
.dg-1 {
	color: var(--dg-1) !important;
}
.dg-2 {
	color: var(--dg-2) !important;
}
.dg-3 {
	color: var(--dg-3) !important;
}
.bg-dg {
	color: white !important;
	&-1 {
		@extend .bg-dg;
		background-color: var(--dg-1) !important;
	}
	&-2 {
		@extend .bg-dg;
		background-color: var(--dg-2) !important;
	}
	&-3 {
		@extend .bg-dg;
		background-color: var(--dg-3) !important;
	}
}
.border-dg-1 {
	border:2px solid var(--dg-1) !important;
}
.border-dg-2 {
	border:2px solid var(--dg-2) !important;
}
.border-dg-3 {
	border:2px solid var(--dg-3) !important;
}



.green-1 {
	color: var(--green-1) !important;
}
.green-2 {
	color: var(--green-2) !important;
}
.green-3 {
	color: var(--green-3) !important;
}
.bg-green {
	color: white !important;
	
	&-1 {
		background-color: var(--green-1) !important;
	}
	&-2 {
		background-color: var(--green-2) !important;
	}
	&-3 {
		background-color: var(--green-3) !important;
	}
}
.border-green-1 {
	border:2px solid var(--green-1) !important;
}
.border-green-2 {
	border:2px solid var(--green-2) !important;
}
.border-green-3 {
	border:2px solid var(--green-3) !important;
}



/******
	Hover
******/
.hover-bg-light {
	&:hover {
		background-color: var(--lg-1) !important;
	}
}


/********
Radius
*********/
.br-08 {
	border-radius: 0.8rem !important;
}
.br-10 {
	border-radius: 1rem !important;
}
.br-12 {
	border-radius: 1.2rem !important;
}
.br-16 {
	border-radius: 1.6rem !important;
}
.br-18 {
	border-radius: 1.8rem !important;
}
.br-20 {
	border-radius: 2rem !important;
}
.br-22 {
	border-radius: 2.2rem !important;
}

.br-50 {
	border-radius: 50% !important;
}



.christmas-blue-and-pink-gradient {
	background: hsla(236, 56%, 45%, 0.8);
	background: linear-gradient(45deg, hsla(236, 56%, 45%, 0.8) 0%, hsla(310, 90%, 85%, 0.8) 100%);
}

.eucalyptus-green-gradient {
	background: hsla(145, 54%, 46%, 1);
	background: linear-gradient(45deg, hsla(145, 54%, 46%, 0.3) 0%, hsla(60, 85%, 66%, 0.3) 100%);
}

.red-gradient {
	background: hsla(354, 64%, 49%, 1);
	background: linear-gradient(45deg, hsla(354, 64%, 49%, 0.8) 0%, hsla(4, 85%, 58%, 0.8) 100%);
}


.christmas-checkbox {
	appearance: none;
	background-color: #fff;
	margin: 0;
	font: inherit;
	color: currentColor;
	min-width: 1.5em;
	min-height: 1.5em;
	border: none;
	border-radius: 0.8em;
	transform: translateY(-0.075em);
}


.facebook-button {
	color: white !important;
	background-color: #3b5998 !important;
	transition: .3s;

	&:hover,
	&:focus {
		transform: scale(1.01)translateY(-0.2em);
	}
}

.whatsapp-button {
	color: white !important;
	background-color: #25D366 !important;
	transition: .3s;

	&:hover,
	&:focus {
		transform: scale(1.01)translateY(-0.2em);
	}
}

.nav-pills {

	.nav-link {
		color: var(--dg-2);
	}

	.active,
	.nav-link:focus {
		background-color: var(--green-1) !important;
	}
	
	.nav-link:hover
	{
		color: white;
		background-color: var(--green-3) !important;
	}

	


}